export const ApplicationErrors = {
    default: 'default',
    'cart-not-found': 'cart-not-found',
    'cart-unavailable': 'cart-unavailable',
    'max-allowed-limit': 'max-allowed-limit',
    'packetery-address-id-invalid': 'packetery-address-id-invalid',
    'invalid-credentials': 'invalid-credentials',
    'invalid-refresh-token': 'invalid-refresh-token',
    'loyalty-club-points-overdraft': 'loyalty-club-points-overdraft',
    'order-emails-not-sent': 'order-emails-not-sent',
    'order-empty-cart': 'order-empty-cart',
    'personal-data-request-type-invalid': 'personal-data-request-type-invalid',
    'blog-category-not-found': 'blog-category-not-found',
    'image-type-invalid': 'image-type-invalid',
    'order-not-found': 'order-not-found',
    'personal-data-hash-invalid': 'personal-data-hash-invalid',
    'product-price-missing': 'product-price-missing',
    'promo-code-already-applied': 'promo-code-already-applied',
    'promo-code-at-most-one-percentage': 'promo-code-at-most-one-percentage',
    'promo-code-discount-coupon-already-applied': 'promo-code-discount-coupon-already-applied',
    'reservations-disabled-at-place': 'reservations-disabled-at-place',
    'no-result-found-for-slug': 'no-result-found-for-slug',
    'store-not-found': 'store-not-found',
    'loyalty-club-points-unverified': 'loyalty-club-points-unverified',
    'loyalty-club-points-changed': 'loyalty-club-points-changed',
    'gift-voucher-not-applicable-for-personal-pickup': 'gift-voucher-not-applicable-for-personal-pickup',
    'promo-code-does-not-meet-minimum-order-price': 'promo-code-does-not-meet-minimum-order-price',
    GIFT_CODE_CANNOT_BE_APPLIED_ON_CART_ITEMS: 'GIFT_CODE_CANNOT_BE_APPLIED_ON_CART_ITEMS',
    'order-stock-availability-error': 'order-stock-availability-error',
    'loyalty-club-points-used-for-voucher': 'loyalty-club-points-used-for-voucher',
    'promo-code-available-for-registered-customer-user-only': 'promo-code-available-for-registered-customer-user-only',
    'promo-code-not-usable-on-vouchers': 'promo-code-not-usable-on-vouchers',
} as const;

export type ApplicationErrorsType = keyof typeof ApplicationErrors;
