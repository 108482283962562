import { PostOfficesBySearchApi, PostOfficesMarkersBySearchApi } from 'graphql/generated';
import nookies from 'nookies';
import { CountryType } from 'types/country';
import { MapMarkerType } from 'types/map';
import { PickupPointType } from 'types/pickupPoint';
import { SearchedStoresType } from 'types/store';

export const mapPostOfficeToStore = (
    postOffice: NonNullable<PostOfficesBySearchApi['postOfficesBySearch']>[number],
): SearchedStoresType => {
    return {
        availabilities: null,
        availabilityInformation: null,
        isAvailableForProducts: null,
        isAvailableForProductsReservation: null,
        store: {
            locationLatitude: postOffice.locationLatitude,
            locationLongitude: postOffice.locationLongitude,
            city: postOffice.city,
            country: getCountryByCountryCode(postOffice.countryCode || ''),
            email: null,
            isPickupPoint: true,
            coordinateDistance: null,
            name: postOffice.name,
            postcode: postOffice.postcode,
            slug: null,
            street: postOffice.address,
            telephone: null,
            uuid: postOffice.identifier,
            openingHours: postOffice.openingHours,
        },
    };
};

export const mapPostOfficesToMapMarker = (
    postOffices: PostOfficesMarkersBySearchApi['postOfficesBySearch'] | undefined,
): MapMarkerType[] => {
    if (!postOffices) {
        return [];
    }

    return postOffices.map((postOffice) => {
        return {
            postOffice: true,
            isPickupPoint: true,
            lat: parseFloat(postOffice.locationLatitude ?? ''),
            lng: parseFloat(postOffice.locationLongitude ?? ''),
            identifier: postOffice.postcode,
        };
    });
};

const getCountryByCountryCode = (countryCode: string): CountryType => {
    if (countryCode === 'CZ') {
        return {
            name: 'Česká republika',
            code: 'CZ',
        };
    }

    return {
        name: countryCode,
        code: countryCode,
    };
};

export const getPostOfficeCzCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('postOffice' in cookies) {
        return JSON.parse(cookies.postOffice);
    }

    return null;
};

const ONE_MONTH = 60 * 60 * 24 * 30;

export const setPostOfficeCzCookie = (mappedPostOfficePoint: PickupPointType): void => {
    nookies.set(undefined, 'postOffice', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeCzCookie = (): void => {
    nookies.destroy(undefined, 'postOffice', {
        path: '/',
    });
};

export const getPostOfficeHuCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('postOfficeHu' in cookies) {
        return JSON.parse(cookies.postOfficeHu);
    }

    return null;
};

export const setPostOfficeHuCookie = (mappedPostOfficePoint: PickupPointType): void => {
    nookies.set(undefined, 'postOfficeHu', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeHuCookie = (): void => {
    nookies.destroy(undefined, 'postOfficeHu', {
        path: '/',
    });
};

export const getPostOfficeSkCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('postOfficeSk' in cookies) {
        return JSON.parse(cookies.postOfficeSk);
    }

    return null;
};

export const setPostOfficeSkCookie = (mappedPostOfficePoint: PickupPointType): void => {
    nookies.set(undefined, 'postOfficeSk', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeSkCookie = (): void => {
    nookies.destroy(undefined, 'postOfficeSk', {
        path: '/',
    });
};

export const getPostOfficePbhCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('postOfficePbh' in cookies) {
        return JSON.parse(cookies.postOfficePbh);
    }

    return null;
};

export const setPostOfficePbhCookie = (mappedPostOfficePoint: PickupPointType): void => {
    nookies.set(undefined, 'postOfficePbh', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficePbhCookie = (): void => {
    nookies.destroy(undefined, 'postOfficePbh', {
        path: '/',
    });
};
